<template>
  <CChartBar
    :datasets="defaultDatasets"
    :options="computedOptions"
    style="height:300px"
     :labels="kecamatan"
  />
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'
import { deepObjectsMerge } from '@coreui/utils/src'
export default {
  name: 'CChartBarExample',
  components: { CChartBar },
  data() {
    return {
      kecamatan: [],
      lp2b: [],
      non_lp2b: [],
      irigasi_macro: [],
      total: [],
      colors: []
    }
  },
  methods: {
    // generate random color
    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
  },
  computed: {
    defaultDatasets () {
      return this.irigasi_macro
    },
    defaultOptions () {
      return {
        maintainAspectRatio: false,
        responsive: true,
      }
    },
    computedOptions () {
      return deepObjectsMerge(this.defaultOptions, this.options || {})
    }
  },
  mounted() {
    this.$store.dispatch("irigasi_macro/persentaseByKecamatan").then((resp) => {
      resp.data.lp2b.forEach(element => {
        this.lp2b.push(element.total)
        this.kecamatan.push(element.kecamatan)
      });

      resp.data.non_lp2b.forEach(element => {
        this.non_lp2b.push(element.total)
      });

      this.irigasi_macro = [
        {
          label: 'LP2B',
          backgroundColor: this.getRandomColor(),
          data: this.lp2b
        },
        {
          label: 'NON LP2B',
          backgroundColor: this.getRandomColor(),
          data: this.non_lp2b
        },
      ]

    }).catch(e => {
      this.$toast.error(e);
    });
  },
}
</script>
