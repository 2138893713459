<template>
  <CChartBar
    :datasets="defaultDatasets"
    :options="computedOptions"
    style="height:300px"
     :labels="labels"
  />
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'
import { deepObjectsMerge } from '@coreui/utils/src'
export default {
  name: 'ChartTotalPanjang',
  components: { CChartBar },
  data() {
    return {
      labels: [],
      total: [],
      colors: []
    }
  },
  methods: {
    // generate random color
    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
  },
  computed: {
    defaultDatasets () {
      return [
        {
          label: 'Kecamatan',
          backgroundColor: this.colors,
          data: this.total
        }
      ]
    },
    defaultOptions () {
      return {
        maintainAspectRatio: false,
        responsive: true,
      }
    },
    computedOptions () {
      return deepObjectsMerge(this.defaultOptions, this.options || {})
    }
  },
  mounted() {
    this.$store.dispatch("jaringan_irigasi/totalPanjang").then((resp) => {
      resp.data.forEach(element => {
        this.labels.push(element.kecamatan);
        this.total.push(element.summary);
        this.colors.push(this.getRandomColor());
      });
      console.log(this.labels, this.total, "total");
    }).catch(e => {
      this.$toast.error(e);
    });
  },
}
</script>
