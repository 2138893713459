<template>
  <CChartBar
    :datasets="defaultDatasets"
    :options="computedOptions"
    style="height:300px"
     :labels="kecamatan"
  />
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'
import { deepObjectsMerge } from '@coreui/utils/src'
export default {
  name: 'ChartLuasStatus',
  components: { CChartBar },
  data() {
    return {
      kecamatan: [],
      total: [],
      colors: []
    }
  },
  methods: {
    // generate random color
    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
  },
  computed: {
    defaultDatasets () {
      return [
        {
          label: "Status Lahan",
          backgroundColor: this.colors,
          data: this.total
        }
      ]
    },
    defaultOptions () {
      return {
        maintainAspectRatio: false,
        responsive: true,
      }
    },
    computedOptions () {
      return deepObjectsMerge(this.defaultOptions, this.options || {})
    }
  },
  mounted() {
    this.$store.dispatch("lp2b/totalLuas").then((resp) => {
      console.log(resp, "resp");
      resp.forEach(element => {
        this.kecamatan.push(element.stts_lhn);
        this.total.push(element.total);
        this.colors.push(this.getRandomColor());
      });
    }).catch(e => {
      this.$toast.error(e);
    });
  },
}
</script>
