<template>
  <CChartDoughnut
    :datasets="defaultDatasets"
    :labels="irigasi_macro"
  />
</template>

<script>
import { CChartDoughnut } from '@coreui/vue-chartjs'

export default {
  name: 'CChartDoughnutExample',
  components: { CChartDoughnut },
  data() {
    return {
      irigasi_macro: [],
      total: [],
      colors: []
    }
  },
  computed: {
    defaultDatasets () {
      return [
        {
          backgroundColor: this.colors,
          data: this.total
        }
      ]
    }
  },
  methods: {
    // generate random color
    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
  },
  mounted() {
    this.$store.dispatch("irigasi_macro/persentase").then((resp) => {

      let grand_total = 0;
      resp.data.forEach(element => {
        grand_total += parseFloat(element.total);
      });

      resp.data.forEach(element => {
        this.irigasi_macro.push(element.jns_lhn)
        this.total.push(((element.total / grand_total) * 100).toFixed())
        this.colors.push(this.getRandomColor())
      });

      console.log( grand_total, "ie naon") 
    }).catch(e => {
      this.$toast.error(e);
    });
  },
}
</script>
